function startMenu() {
  let hamburguer = jQuery('.js-hamburger');
  let menu = jQuery('.js-menu');
  let header = jQuery('.js-header');

  hamburguer.on('click', function (e) {
    e.preventDefault();
    if (hamburguer.hasClass('open')) {
      hamburguer.removeClass('open');
      menu.removeClass('open');
      header.removeClass('open');
    } else {
      hamburguer.addClass('open');
      menu.addClass('open');
      header.addClass('open');
    }
  });

  $('.js-anchor').on('click', function() {
    let delta = 0;
    let target = $(this).attr('href');
    hamburguer.removeClass('open');
    menu.removeClass('open');
    header.removeClass('open');
    $('html, body').animate({
      scrollTop: $(target).offset().top - delta,
    }, 800);
  });
}

module.exports = {
  startMenu: startMenu,
};
