import menu from '../modules/menu';
import lightbox from '../modules/lightbox';
import markers from '../modules/markers';
import Swiper from 'swiper/bundle';
import AOS from 'aos';

export default {
  init() {
    // JavaScript to be fired on all pages

    if (jQuery('.escenariosSwiper').length) {
      let offer = document.querySelector('.numberSlides');
      offer.innerHTML =
        '01 / 0' + jQuery('.escenariosSwiper .swiper-slide').length;

        if(window.innerWidth >= 768){
          const escenariosSwiper = new Swiper('.escenariosSwiper', {
            // Optional parameters
            effect: 'cards',
            cardsEffect: {
              slideShadows: false,
              perSlideOffset: 6,
              perSlideRotate: 10,
            },
            grabCursor: true,
            loop: true,
            spaceBetween: 0,
            runCallbacksOnInit: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            on: {
              slideChange: function () {
                const index_currentSlide = escenariosSwiper.realIndex;

                let offer = document.querySelector('.numberSlides');
                offer.innerHTML =
                  '0' +
                  (index_currentSlide + 1) +
                  ' / 0' +
                  jQuery('.escenariosSwiper .swiper-slide').length;
              },
            },
            // Navigation arrows
            navigation: {
              nextEl: '.escenarios-arrow-next',
              prevEl: '.escenarios-arrow-prev',
            },
          });
        }else{
          const escenariosSwiper = new Swiper('.escenariosSwiper', {
            // Optional parameters
            effect: 'slide',
            loop: true,
            slidesPerView: 1.1,
            spaceBetween: 16,
            on: {
              slideChange: function () {
                const index_currentSlide = escenariosSwiper.realIndex;

                let offer = document.querySelector('.numberSlides');
                offer.innerHTML =
                  '0' +
                  (index_currentSlide + 1) +
                  ' / 0' +
                  jQuery('.escenariosSwiper .swiper-slide').length;
              },
            },
            // Navigation arrows
            navigation: {
              nextEl: '.escenarios-arrow-next',
              prevEl: '.escenarios-arrow-prev',
            },
          });
        }
    }

    if (jQuery('.cardsSwiper').length) {
        if(window.innerWidth <= 768) {
          jQuery('.js-swiper-wrapper').addClass('swiper-wrapper');
          jQuery('.js-swiper-slide').addClass('swiper-slide');
         
          new Swiper('.cardsSwiper', {
            effect: 'slide',
            loop: false,
            slidesPerView: 1.1,
            spaceBetween: 16,
          });
        } 
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    menu.startMenu();
    lightbox.startLightbox();
    markers.startMarkers();
    AOS.init();
  },
};
