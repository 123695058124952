function startLightbox() {
  $('.ml-video').magnificPopup({
    preloader: true,
    type: 'iframe',
    iframe: {
          patterns: {
              youtube: {
                  index: 'youtube.com/',
                  id: 'v=',
                  src: '//www.youtube.com/embed/%id%?enablejsapi=1&autoplay=1&rel=0',
              },
          },
          srcAction: 'iframe_src',
      },
  });
}

module.exports = {
  startLightbox: startLightbox,
};
