function startMarkers() {
  let numItems = ($('.main section').length - 1);

  for (let i = 1; i < numItems; i++) {
    $('.js-markers').append('<div class="relative dot rounded-full transition duration-300 shrink-0"></div>');
  }

  $(document).on('scroll', changeDot);

  function changeDot() {
    let scrollTop = $('html, body').scrollTop(),
        section = $('.main section');
  
    section.each(function (i, elem) {
      let that = $(elem),
            sectionScroll = that.offset().top,
            sectionHeigth = that.outerHeight(),
            distance = sectionScroll - scrollTop,
            procent = (-distance * 100) / (sectionHeigth);
        
        if (procent >= -10 && procent <= 90) {
           
          let dots = $('.dot');
          let dotsEq = dots.eq(i) 
            
            dotsEq.prev().removeClass('active');
            dotsEq.next().removeClass('active');
            dotsEq.addClass('active');
        }
    })
  }

  /*$(document).on('scroll', changeParallax);

  function changeParallax() {
    const scrollTop = window.scrollY;

    $('.parallax').each(function() {
      let canUserSeeIt = inViewport($(this));
      let speed = $(this).data('speed');
      let topItem = Math.abs(parseInt($(this).css('top')));

      console.log(topItem);
      if(canUserSeeIt){
        $(this).css('transform', 'translateY(' + ((scrollTop - topItem) * speed) + 'px)');

      }
    });
  }

  function inViewport($ele) {
    var lBound = $(window).scrollTop(),
      uBound = lBound + $(window).height(),
      top = $ele.offset().top,
      bottom = top + $ele.outerHeight(true);

    return (top > lBound && top < uBound) ||
      (bottom > lBound && bottom < uBound) ||
      (lBound >= top && lBound <= bottom) ||
      (uBound >= top && uBound <= bottom);
  }*/


}

module.exports = {
  startMarkers: startMarkers,
};
